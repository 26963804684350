import sjcl from 'sjcl'
import $ from "jquery";
// import queryString from 'query-string';
import UtilFunctions from '../UtilFunctions';

var serviceauthPassiveOauthUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/passive/native/allow';
var serviceauthOauthUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/native/allow';
var serviceauthTokenUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/native/token';
var serviceauthTokenRefreshUrl = 'https://weblogin.asu.edu/serviceauth/oauth2/token';

var SS_SA_CODE_VERIFIER = 'catalog.serviceauth.codeVerifier';
var SS_SA_STATE = 'catalog.serviceauth.state';
var DPL_BASE_URL = 'https://api.myasuplat-dpl.asu.edu/api';

var serviceauthId = process.env.REACT_APP_HOST_ENV === 'prod' ? 'catalog-class-search-app' : 'catalog-class-search-app-nonprod';
var serviceauthSecret = 'serviceauth-public-agent';
// var serviceauthRedirectUri = 'https://dwl5quiztcc03.cloudfront.net/catalog';

var scopes = [
	'https://api.myasuplat-dpl.asu.edu/scopes/principal/read:self', 
	'https://api.myasuplat-dpl.asu.edu/scopes/person/read:self',
];

var serviceauthRedirectUri= process.env.REACT_APP_HOST_ENV === 'prod' ? 'https://catalog.apps.asu.edu/catalog' : 'https://catalog-'+process.env.REACT_APP_HOST_ENV+'.apps.asu.edu/catalog'
var SS_JWT_TOKEN= 'catalog.jwt.token'
var SS_JWT_REFRESH_TOKEN= 'catalog.jwt.refresh.token'
var SS_JWT_EXPIRATION= 'catalog.jwt.expiration'
var SS_PASSIVE_AUTH= 'catalog.passive.auth'
var SS_SEARCH_PARAMS= 'catalog.search.params'
var SS_NAME='catalog.ss.name'
var SS_USER = 'catalog.ss.userinfo'
var SS_VIEW_AS = 'catalog.ss.viewas'
var SS_VIEW_AS_USER = 'catalog.ss.viewas.user'
var SS_IS_ONLINE = 'catalog.ss.isonline'
var SS_IS_STUDENT = 'catalog.ss.isstudent'
var SS_IS_STAFF = 'catalog.ss.isstaff'
var SS_CAREER = 'catalog.ss.career'
var SS_ASU_COMPONENT = 'catalog.ss.asu.component';
var SS_ASU_SWAP_CLASS = 'catalog.ss.asu.swap.class';

//9 min
const TOKEN_EXPIRES_IN_SECONDS = 540 


function sha256base64url(message) {
	return sjcl.codec.base64url.fromBits(sjcl.hash.sha256.hash(message));
}

function generateRandomString(alphabet, length) {
	var randArr = new Uint8Array(length);
	var ret = '';
	window.crypto.getRandomValues(randArr);
	for (var i = 0; i < randArr.length; i++) {
		var j = randArr[i] % 64;
		ret += alphabet.substring(j, j + 1);
	}
	return ret;
}

function generateCodeVerifier() {
	var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.";
	var length = 128;
	return generateRandomString(alphabet, length)
}

function generateRandomServiceauthState() {
	var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.";
	var length = 64;
	return generateRandomString(alphabet, length)
}

function getAccessToken() {
	//console.log('token name '+SS_JWT_TOKEN)
	
	try{
		const currentDate = new Date();
		const tokenExpirationDate = new Date(sessionStorage.getItem(SS_JWT_EXPIRATION))

		//console.log("currentdate "+ currentDate)
		//console.log(" tokenExpirationDate "+ tokenExpirationDate )
		if(sessionStorage.getItem(SS_JWT_TOKEN) !== null && currentDate > tokenExpirationDate){
			//console.log("old token "+sessionStorage.getItem(SS_JWT_TOKEN))
			//console.log("need a refresh token!!")
			serviceauth.getRefreshedAccessToken()
			//console.log("new token "+sessionStorage.getItem(SS_JWT_TOKEN))
		}
		// else{
		// 	//console.log("don't need a refresh token!!")
		// }
	}catch(e){
		// console.log("error")
	}

	return sessionStorage.getItem(SS_JWT_TOKEN);
}



function handlePrincipalData(data) {

	// TODO: check to ensure this is primary principal

	// $('#asuriteid').text(data.principal);
	// $('#emplid').text(data.owner.emplid);
	//var emplid = data.owner.emplid;

	$.ajax({
		type: "GET",
		url: DPL_BASE_URL + '/person/'+data.owner.emplid,
		beforeSend: this.ajaxBeforeSendFunc,
		cache: false,
		dataType: 'json',
		success: function(data) {
			// console.log('person success! data=['+JSON.stringify(data)+']');
			handlePersonData(data);
		},
		error: function(xhr, status, error) {
			// console.log("person error: xhr: ["+JSON.stringify(xhr)+"] status=["+status+"], error=["+error+"]");
			if (xhr.status === 401) {
				serviceauth.reauth();
			}
		}
	});

}

function handlePersonData(data) {
	
	// TODO: test for presence
	var name = data.names.PRI.givenName + ' ' + data.names.PRI.familyName;
	// console.log("name: "+name)
	sessionStorage.setItem(SS_NAME,name)
}

// function getAdditionalUrl(){
// 	//var additionalUrl = window.location.href.replace(serviceauthRedirectUri,"");

// 	var pathObj = window.location.search
	
	
// 	return  
// }

const serviceauth = {
	serviceauthRedirectUri: serviceauthRedirectUri,
	SS_JWT_TOKEN: SS_JWT_TOKEN,
	SS_PASSIVE_AUTH:SS_PASSIVE_AUTH,
	SS_SEARCH_PARAMS:SS_SEARCH_PARAMS,
	SS_NAME:SS_NAME,
	SS_USER:SS_USER,
	SS_VIEW_AS: SS_VIEW_AS,
	SS_VIEW_AS_USER: SS_VIEW_AS_USER,
	SS_IS_ONLINE: SS_IS_ONLINE,
	SS_IS_STUDENT: SS_IS_STUDENT,
	SS_IS_STAFF: SS_IS_STAFF,
	SS_CAREER:SS_CAREER,
	SS_SA_STATE:SS_SA_STATE,
	SS_SA_CODE_VERIFIER:SS_SA_CODE_VERIFIER,
	SS_ASU_COMPONENT: SS_ASU_COMPONENT,
	SS_ASU_SWAP_CLASS: SS_ASU_SWAP_CLASS,
	serviceauthTokenUrl:serviceauthTokenUrl,
	serviceauthId:serviceauthId,
	serviceauthSecret:serviceauthSecret,
	DPL_BASE_URL:DPL_BASE_URL,
	getAccessToken:getAccessToken,

	getParam(nameIn) {
		var qs = window.location.search.substring(1, window.location.search.length);
		// console.log(qs)
		if (qs.length !== 0) {
			qs = qs.replace(/\+/g, ' ');
			var qsargs = qs.split('&');
			for (var i = 0; i < qsargs.length; i++) {
				var j = qsargs[i].indexOf('=');
				if (j === -1) {
					var name = qsargs[i];
					var value = '';
				} else {
					name = qsargs[i].substring(0, j);
					value = unescape(qsargs[i].substring(j + 1));
				}
				if (nameIn === name) {
					return value;
				}
			}
		}
		return null;
	},
	async handleOauthCode(code, state, success, err) {
		var storedState = sessionStorage.getItem(SS_SA_STATE);
		var codeVerifier = sessionStorage.getItem(SS_SA_CODE_VERIFIER);
		sessionStorage.removeItem(SS_SA_STATE);
		sessionStorage.removeItem(SS_SA_CODE_VERIFIER);

		// console.log("recevied state=["+state+"]");
		// console.log("stored state  =["+storedState+"]");
		if (!state || state.length < 1 || storedState !== state) {
			err("received unexpected state [" + state + "] - expected [" + storedState + "]");
			return;
		}

		//console.log("codeVerifier  =["+codeVerifier+"]");
		var requestData = {
			grant_type: 'authorization_code',
			code: code,
			redirect_uri: serviceauthRedirectUri,
			client_id: serviceauthId,
			client_secret: serviceauthSecret,
			code_verifier: codeVerifier,
		};

		//console.log('token request data: ['+JSON.stringify(requestData)+"]");
		$.ajax({
			type: "POST",
			url: serviceauthTokenUrl,
			cache: false,
			data: requestData,
			async:false,
			success: function (data) {
				//console.log('token endpoint success! data=[' + JSON.stringify(data));
				sessionStorage.setItem(SS_JWT_TOKEN, data.access_token);
				sessionStorage.setItem(SS_JWT_REFRESH_TOKEN, data.refresh_token);
				var t = new Date(new Date().getTime() + (TOKEN_EXPIRES_IN_SECONDS * 1000));
				sessionStorage.setItem(SS_JWT_EXPIRATION, t);

				// TODO: verify we got scopes we need
				success();
			},
			error: function (xhr, status, error) {
				err("error: xhr: [" + JSON.stringify(xhr) + "] status=[" + status + "], error=[" + error + "]");
			}
		});
	},
	async getRefreshedAccessToken( ) {
		// console.log("in refresh token client_id " +serviceauthId)
		var refreshToken = sessionStorage.getItem(SS_JWT_REFRESH_TOKEN);

		var requestData = {
			grant_type: 'refresh_token',
			refresh_token: refreshToken,
			client_id: serviceauthId,
			client_secret: serviceauthSecret,
			scope: scopes
		};

		$.ajax({
			type: "POST",
			url: serviceauthTokenRefreshUrl,
			cache: false,
			data: requestData,
			async:false,
			success: function (data) {
				// console.log('REFRESH token endpoint success! data=[' + JSON.stringify(data));
				sessionStorage.setItem(SS_JWT_TOKEN, data.access_token);
				// sessionStorage.setItem(SS_JWT_REFRESH_TOKEN, data.refresh_token);
				var t = new Date(new Date().getTime() + (TOKEN_EXPIRES_IN_SECONDS * 1000));
				sessionStorage.setItem(SS_JWT_EXPIRATION, t);

				// // TODO: verify we got scopes we need
			//	success();
			},
			error: function (xhr, status, error) {
				//err("error: xhr: [" + JSON.stringify(xhr) + "] status=[" + status + "], error=[" + error + "]");
				console.log("error: xhr: [" + JSON.stringify(xhr) + "] status=[" + status + "], error=[" + error + "]");
				// console.log("trying to use refresh token "+refreshToken)
      			//this.reauth();
			}
		});
	},
	reauth() {
		// console.log("reauthing...");
		// TODO: detect fail loop? throttle?
		this.clearSession();
		this.redirectToServiceauth();
	},

	signout(){
		this.clearSession();
		window.location.reload()
	},
	clearSession(){
		sessionStorage.removeItem(SS_JWT_TOKEN);
		sessionStorage.removeItem(SS_NAME);
		sessionStorage.removeItem(SS_VIEW_AS);
		sessionStorage.removeItem(SS_VIEW_AS_USER);
		sessionStorage.removeItem(SS_IS_ONLINE);
		sessionStorage.removeItem(SS_IS_STUDENT);
		sessionStorage.removeItem(SS_IS_STAFF);
		sessionStorage.removeItem(SS_CAREER);
		sessionStorage.removeItem(SS_PASSIVE_AUTH);
		sessionStorage.removeItem("app.settings")
	},
	redirectToServiceauth() {
		
		window.location.replace(this.redirectToServiceauthUrl());
	},
	redirectToServiceauthUrl(forceAuth) {
		//console.log("when redirecting, params are: "+window.location.search)
		var codeVerifier = generateCodeVerifier();
		var codeChallenge = sha256base64url(codeVerifier);
		var state = generateRandomServiceauthState()//+"URLPARAMS"+getAdditionalUrl();
		//console.log("before saving, window.location.search: "+window.location)
		// sessionStorage.setItem(SS_SEARCH_PARAMS,window.location.search + window.location.hash)
		
		//Already merged with the catalog branch for this portion, there might be some conflicts,
  		//so just keep this code, it should be the same
		//keep this line when merging with the catalog branch
		sessionStorage.setItem(SS_SEARCH_PARAMS,window.location.pathname + window.location.search + window.location.hash)


		//console.log("after saving, SS_SEARCH_PARAMS: "+sessionStorage.getItem(SS_SEARCH_PARAMS))
		var scopeParam = '';
		for (var i = 0; i < scopes.length; i++) {
			if (scopeParam.length > 0) {
				scopeParam += ' ';
			}
			scopeParam += scopes[i];
		}

		sessionStorage.setItem(SS_SA_CODE_VERIFIER, codeVerifier);
		sessionStorage.setItem(SS_SA_STATE, state);

		
		var url = forceAuth ? serviceauthOauthUrl : serviceauthPassiveOauthUrl;
		url += '?response_type=code';
		url += '&client_id=' + encodeURIComponent(serviceauthId);
		url += '&redirect_uri=' + encodeURIComponent(serviceauthRedirectUri);
		url += '&state=' + encodeURIComponent(state);
		url += '&code_challenge_method=S256';
		url += '&code_challenge=' + codeChallenge;
		url += '&scope=' + encodeURIComponent(scopeParam);
		// if(!forceAuth)
		// 	url += '&authenticator='+ encodeURIComponent('{cookies:ASUWEBAUTH}');
		// console.log('redirecting to oauth server at url=[' + url + ']');
		// console.log("serviceauthRedirectUri "+serviceauthRedirectUri)
		return (url);
	},
	
	async getUserInfo() {
		// console.log('auth done; Now trying to get user info');

		$.ajax({
			type: "GET",
			url: DPL_BASE_URL + '/principal/me',
			beforeSend: this.ajaxBeforeSendFunc,
			cache: false,
			dataType: 'json',
			async:false,
			success: function (data) {
				// console.log('principal success! data=[' + JSON.stringify(data) + ']');
				handlePrincipalData(data);
			},
			error: function (xhr, status, error) {
				// console.log("principal error: xhr: [" + JSON.stringify(xhr) + "] status=[" + status + "], error=[" + error + "]");
				if (xhr.status === 401) {
					serviceauth.reauth();
				}
			}
		});


	},
	ajaxBeforeSendFunc(xhr) {
		//console.log('Bearer '+getAccessToken())
		xhr.setRequestHeader('Authorization', 'Bearer '+getAccessToken());
	},
	composeUrlFromState(url){
		var obj = JSON.parse(url)
		return obj;
	},
	getRequestObject(url,method){

		//check If TokenExpired

		const myHeaders = new Headers();
		myHeaders.append('Authorization', 'Bearer '+getAccessToken());

		const myRequest = new Request(url, {
			method: method,
			headers: myHeaders,
			mode: 'cors',
			cache: 'default',
			credentials: 'include'
		});

		// console.log(myRequest)
		return myRequest
	},
	processResponse(response){
		try {
			if(response.output === "JsonWebTokenError" ||
			response.output === "TokenExpiredError"){
				serviceauth.reauth();
			}else{
				return response.json();
			}
		} catch (error) {
			
		}
		return {}
	},
	async authComplete(){
		 //student data
		//  console.log("in authcomplete")
		const asuComponent = this.getParam("asu_component");
		const swapClass = this.getParam("asu_swap_class");
		if(asuComponent && asuComponent !== ""){
			sessionStorage.setItem(this.SS_ASU_COMPONENT,asuComponent);
			sessionStorage.setItem(this.SS_ASU_SWAP_CLASS,swapClass);
		}else{
			sessionStorage.removeItem(this.SS_ASU_COMPONENT);
			sessionStorage.removeItem(this.SS_ASU_SWAP_CLASS);
		}

		 $.ajax({
			type: "GET",
			url: serviceauth.DPL_BASE_URL + '/principal/me',
			beforeSend: serviceauth.ajaxBeforeSendFunc,
			cache: false,
			dataType: 'json',
			async:false,
			success:  (data)=> {
			  // console.log('principal success! data=[' + JSON.stringify(data) + ']');
			  $.ajax({
				type: "GET",
				url: UtilFunctions.getUserUrl(),
				beforeSend: serviceauth.ajaxBeforeSendFunc,
				async:false,
				cache: false,
				dataType: 'json',
				success: (user) => {
					if(user.viewAs){
						sessionStorage.setItem(serviceauth.SS_VIEW_AS_USER,user.user)
						sessionStorage.setItem(serviceauth.SS_VIEW_AS,"Y")
					}else{
						sessionStorage.setItem(serviceauth.SS_VIEW_AS,"N")
						sessionStorage.setItem(serviceauth.SS_VIEW_AS_USER,"")
					}
					sessionStorage.setItem(serviceauth.SS_IS_ONLINE,user.online ? "Y" : "N")
					sessionStorage.setItem(serviceauth.SS_CAREER,user.career)
					sessionStorage.setItem(serviceauth.SS_IS_STUDENT,user.student ? "Y" : "N")
					sessionStorage.setItem(serviceauth.SS_IS_STAFF,user.isStaff ? "Y" : "N")
				  
				},
				error: function(xhr, status, error) {
				  // console.log("person error: xhr: ["+JSON.stringify(xhr)+"] status=["+status+"], error=["+error+"]");
				  if (xhr.status === 401) {
					serviceauth.reauth();
				  }
				}
			  });

			  $.ajax({
				type: "GET",
				url: serviceauth.DPL_BASE_URL + '/person/'+data.owner.emplid,
				beforeSend: serviceauth.ajaxBeforeSendFunc,
				cache: false,
				dataType: 'json',
				success: (data) => {
				  // console.log('person success! data=['+JSON.stringify(data)+']');
				  var name = data.names.PRI.givenName //+ ' ' + data.names.PRI.familyName;
					// console.log("name: "+name)
				  sessionStorage.setItem(serviceauth.SS_NAME,name)
				  
				},
				error: function(xhr, status, error) {
				  // console.log("person error: xhr: ["+JSON.stringify(xhr)+"] status=["+status+"], error=["+error+"]");
				  if (xhr.status === 401) {
					serviceauth.reauth();
				  }
				}
			  });
			},
			error: function (xhr, status, error) {
			  // console.log("principal error: xhr: [" + JSON.stringify(xhr) + "] status=[" + status + "], error=[" + error + "]");
			  if (xhr.status === 401) {
				serviceauth.reauth();
			  }
			}
		  });

		  //get user info
		//   console.log("Getting user info from api: "+UtilFunctions.getUserUrl())
		//   await fetch(serviceauth.getRequestObject(UtilFunctions.getUserUrl(), "GET"))
		//   .then((response) => {
		// 	if (response.status === 401) {
		// 	  serviceauth.reauth();
		// 	}
		// 	return response.json();
		//   })
		//   .then(async (user) => {
		// 	//  console.log(user)
		// 	//sessionStorage.setItem(serviceauth.SS_IS_ONLINE,user.isOnlineOnly ? "Y" : "N")
		// 	if(user.viewAs){
		// 	  sessionStorage.setItem(serviceauth.SS_VIEW_AS_USER,user.user)
		// 	  sessionStorage.setItem(serviceauth.SS_VIEW_AS,"Y")
		// 	}else{
		// 	  sessionStorage.setItem(serviceauth.SS_VIEW_AS,"N")
		// 	  sessionStorage.setItem(serviceauth.SS_VIEW_AS_USER,"")
		// 	}
		// 	sessionStorage.setItem(serviceauth.SS_IS_ONLINE,user.online ? "Y" : "N")
		// 	sessionStorage.setItem(serviceauth.SS_CAREER,user.career)
		// 	sessionStorage.setItem(serviceauth.SS_IS_STUDENT,user.student ? "Y" : "N")
		// 	sessionStorage.setItem(serviceauth.SS_IS_STAFF,user.isStaff ? "Y" : "N")
			

			  
		//   }).catch(err => { console.log(err) });
	}


}





export default serviceauth;